import { MegaMenuPresenterContext } from 'frontend-container/components/Menu/components/MegaMenu/presenter/context';

import {
  PropsWithChildren,
  useModalPresenterStore,
} from '@ac/react-infrastructure';

export const MegaMenuPresenterContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useModalPresenterStore<undefined, undefined>();

  return (
    <MegaMenuPresenterContext.Provider value={store}>
      {props.children}
    </MegaMenuPresenterContext.Provider>
  );
};
