import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { profileCenterLandingPagePathname } from 'frontend-container/components/LandingPage/location';
import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context/context';
import { ContextComponent } from 'frontend-container/components/Menu/components/Context/ContextComponent';
import { useDateTimeContext } from 'frontend-container/components/Menu/components/Context/useDateTimeContext';
import { ContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { handleMenuItemRedirect } from 'frontend-container/components/Menu/components/Item/handleMenuItemRedirect';
import { handleUnsavedChangesRedirect } from 'frontend-container/components/Menu/components/Item/handleUnsavedChangesRedirect';
import { getCurrentPropertyIdentifierColor } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { getActiveContextOption } from 'frontend-container/components/Menu/utils/getActiveContextOption';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { useInitializeContextForRouter } from 'frontend-container/components/Router/hooks/useInitializeContext';
import { useSelectContext } from 'frontend-container/components/Router/utils/useSelectContext';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { AcSelectOption, AcSelectValue } from '@ac/web-components';

interface Props {
  color?: string;
}

const isOptionContext = (
  option: Context | Array<AcSelectOption<AcSelectValue>>
): option is Context => {
  return !Array.isArray(option);
};

export const ContextManager = ({ color }: Props): JSX.Element | null => {
  const initializeContextForRouter = useInitializeContextForRouter();
  const isRouterInitialized = useRouterContext((store) => store.isInitialized);
  const selected = useRouterContext((store) => store.currentUnit);

  const selectContext = useSelectContext();

  const activeContextOption = getActiveContextOption();

  const { t } = useTranslation();
  const dateTimeHook = useDateTimeContext(selected?.type);

  const handleSelectConfiguration = (
    selectedOption: Array<AcSelectOption<AcSelectValue>>
  ): void => {
    const selectedOptionValue = selectedOption[0]?.value ?? '';
    const pathToLoad = `${window.location.origin}${selectedOptionValue}`;

    if (selectedOptionValue === profileCenterLandingPagePathname) {
      handleUnsavedChangesRedirect(
        false,
        `${window.location.origin}${profileCenterLandingPagePathname}`,
        async () =>
          await handleMenuItemRedirect(pathToLoad, undefined, undefined, true)
      );
      window.location.replace(
        `${window.location.origin}${profileCenterLandingPagePathname}`
      );

      return;
    }

    handleUnsavedChangesRedirect(
      false,
      pathToLoad,
      async () =>
        await handleMenuItemRedirect(pathToLoad, undefined, undefined, true)
    );
    handleMenuItemRedirect(pathToLoad);
  };

  const handleSelect = (
    option: Context | Array<AcSelectOption<AcSelectValue>>,
    discardUnsavedChanges = false,
    tabId = ''
  ): Promise<void> | void => {
    if (tabId === ContextOption.configuration) {
      return handleSelectConfiguration(
        option as Array<AcSelectOption<AcSelectValue>>
      );
    }

    if (isOptionContext(option)) {
      return selectContext(option, discardUnsavedChanges);
    }

    throw new Error('Context not defined');
  };

  useEffect(() => {
    const shouldReinit =
      (ContextOption.property !== activeContextOption &&
        selected?.type === ContextType.PROPERTY) ||
      (ContextOption.cro !== activeContextOption &&
        selected?.type === ContextType.CRO) ||
      (ContextOption.profileCenter !== activeContextOption &&
        selected?.type === ContextType.PROFILE_CENTER) ||
      isContextChangedFromOutside();

    if (shouldReinit) {
      void initializeContextForRouter();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContextOption, selected?.type]);

  const getColor = (): string | undefined => {
    if (selected?.type === ContextType.PROPERTY) {
      return color ? color : getCurrentPropertyIdentifierColor();
    }

    return;
  };

  const isContextChangedFromOutside = (): boolean => {
    const id = selected?.id;

    return !(
      id === SessionService.getPropertyId() ||
      id === SessionService.getCentralReservationOfficeId() ||
      id === SessionService.getProfileCenterId()
    );
  };

  if (LoginService.isSuperUser()) {
    return null;
  }

  return !isRouterInitialized ? (
    <ac-loader-covering />
  ) : (
    <ContextComponent
      selectedContext={selected}
      useDateTime={dateTimeHook}
      onSelect={handleSelect}
      color={getColor()}
      dateLabel={t('MENU.CONTEXT.PROPERTY.DATE')}
      timeLabel={t('MENU.CONTEXT.PROPERTY.TIME')}
      shouldActiveConfiguration={
        activeContextOption === ContextOption.configuration &&
        selected?.type === ContextType.PROPERTY
      }
      distanceFromTarget={3}
    />
  );
};
