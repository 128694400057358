import { useMemo } from 'react';
import {
  getSearchForNumberOptions,
  getSearchForOptions,
} from 'frontend-container/components/Menu/components/GlobalSearchModal/configConstants';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { getAllowedSearchByItems } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/getAllowedSearchByItems';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { FeatureToggleName } from '@ac/library-api';

interface Props {
  globalSearchItems: GlobalSearchMenuElementItem[];
}

export interface SearchItems {
  searchForOptions: GlobalSearchMenuElementItem[];
  searchForNumberOptions: GlobalSearchMenuElementItem[];
}

export const useSearchItems = ({ globalSearchItems }: Props): SearchItems => {
  const propertyContextData = getPropertyContextData();
  const isEmberToReactReservationsDashboardReactEnabled =
    propertyContextData?.featureToggles.find((toggle) => {
      return (
        toggle.featureName ===
        FeatureToggleName.EmberToReactReservationsDashboardReactEnabled
      );
    })?.enabled;
  const isEmberToReactFrontDeskDashboardReactEnabled =
    propertyContextData?.featureToggles.find((toggle) => {
      return (
        toggle.featureName ===
        FeatureToggleName.EmberToReactFrontDeskDashboardReactEnabled
      );
    })?.enabled;

  const searchForOptions = useMemo(() => {
    const allSearchByItems = getSearchForOptions(
      isEmberToReactReservationsDashboardReactEnabled,
      isEmberToReactFrontDeskDashboardReactEnabled
    );

    return getAllowedSearchByItems(allSearchByItems, globalSearchItems);
  }, [
    globalSearchItems,
    isEmberToReactReservationsDashboardReactEnabled,
    isEmberToReactFrontDeskDashboardReactEnabled,
  ]);

  const searchForNumberOptions = useMemo(() => {
    const allSearchByItems = getSearchForNumberOptions(
      isEmberToReactReservationsDashboardReactEnabled,
      isEmberToReactFrontDeskDashboardReactEnabled
    );

    return getAllowedSearchByItems(allSearchByItems, globalSearchItems);
  }, [
    globalSearchItems,
    isEmberToReactReservationsDashboardReactEnabled,
    isEmberToReactFrontDeskDashboardReactEnabled,
  ]);

  return { searchForOptions, searchForNumberOptions };
};
