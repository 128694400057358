import { getCroContexts } from 'frontend-container/components/Menu/components/CroContext/service';
import { getPropertyUnits } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { MenuElementItem } from 'frontend-container/components/Menu/types';
import { isModuleEditableInGlobalRegionOnly } from 'frontend-container/components/Menu/utils/isModuleEditableInGlobalRegionOnly';
import { isCentralReservationOfficeModule } from 'frontend-container/components/Menu/utils/modules/centralReservationOffice';
import { getInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { isReadOnlyRequired } from 'frontend-container/components/ReadOnlyMode/isReadOnlyRequired';
import {
  removeReadOnlyByUser,
  setReadOnlyMode,
} from 'frontend-container/components/ReadOnlyMode/setReadOnlyMode';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import { redirectToRegion } from 'frontend-container/utils/region/redirectToRegion';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import {
  getCurrentRegionCode,
  getGlobalRegionCode,
} from '@ac/library-utils/dist/utils/multi-region';

export const handleMenuItemRedirect = async (
  link: string,
  menuElementItems?: MenuElementItem[],
  onBeforeRedirectCallback?: () => void,
  skipInMenuCheck?: boolean
): Promise<void> => {
  const propertyId = SessionService.getPropertyId() ?? '';
  const tenantId = LoginService.authData()?.tenantId ?? '';
  const fullLink = link
    .replace(/:id/g, propertyId)
    .replace(/:customerId/g, tenantId);

  const isLinkInMenu = menuElementItems?.find(
    (element) => element.link === link
  );
  if (!isLinkInMenu && !skipInMenuCheck) {
    return;
  }
  onBeforeRedirectCallback?.();

  const currentRegionCode = getCurrentRegionCode();

  /**
   * Note: On some CRO pages we want to redirect user to the deployed CRO region that we assume
   * is the closest to recipients (in terms of location). User may be temporarily in another
   * region than deployed to present correctly reservation view under CRO modules.
   */
  if (isCentralReservationOfficeModule(fullLink)) {
    const croContexts = getCroContexts();
    const croSelectedContext = getInitialContext(
      croContexts,
      SessionService.getCentralReservationOfficeId()
    );
    const croContextRegion = findRegionData(
      croSelectedContext?.regionCode ?? getGlobalRegionCode()
    );

    if (
      croSelectedContext &&
      croContextRegion &&
      croContextRegion.code.toLowerCase() !== currentRegionCode.toLowerCase()
    ) {
      redirectToRegion(
        croContextRegion.code,
        croContextRegion.domain,
        croSelectedContext,
        fullLink
      );

      return;
    }
  }

  // Only modules which are editable in global region care for readOnly mode.
  // Each time we enter into such module, if we are not in global region, we should be in readOnly mode.
  // That's why we active readOnly mode for modules other then the ones editable in global region.
  if (
    isModuleEditableInGlobalRegionOnly() &&
    !isModuleEditableInGlobalRegionOnly(fullLink)
  ) {
    if (isReadOnlyRequired()) {
      setReadOnlyMode(true);
      removeReadOnlyByUser();
    }

    const units = getPropertyUnits();
    const property = units.find((unit) => unit.unitId === propertyId);
    if (property?.regionCode !== currentRegionCode) {
      window.location.href = fullLink;

      return;
    }
  }

  if (link.indexOf('?foreignCurrencyExchange=true') >= 0) {
    window.location.href = fullLink;
  } else {
    navigateToPage(fullLink);
  }

  return;
};
