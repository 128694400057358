import React from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'frontend-container/components/Menu/components/Context';

import {
  AlignItems,
  FlexDirection,
  JustifyContent,
  Orientation,
} from '@ac/web-components';

interface Props {
  selectedProperty?: Context | undefined;
  businessDate?: string;
  propertyTime?: string;
}

export const SelectedPropertyInfotipBody = ({
  selectedProperty,
  businessDate,
  propertyTime,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ac-flex direction={FlexDirection.column} class="ac-padding-sm">
      <ac-flex
        alignItems={AlignItems.center}
        class="ac-spacing-bottom-sm ac-gap-sm"
      >
        {selectedProperty?.name && <ac-text>{selectedProperty.name}</ac-text>}
      </ac-flex>
      <ac-flex
        alignItems={AlignItems.center}
        justifyContent={JustifyContent.spaceBetween}
      >
        {selectedProperty?.regionCode && (
          <ac-text-group
            label={t('MENU.CONTEXT.REGION')}
            orientation={Orientation.vertical}
            class="ac-spacing-right-sm"
          >
            <ac-text uppercase>{selectedProperty.regionCode}</ac-text>
          </ac-text-group>
        )}
        {businessDate && selectedProperty && (
          <ac-text-group
            label={t('MENU.CONTEXT.PROPERTY.DATE')}
            orientation={Orientation.vertical}
            class="ac-spacing-right-sm"
          >
            <span>{businessDate}</span>
          </ac-text-group>
        )}
        {propertyTime && selectedProperty && (
          <ac-text-group
            label={t('MENU.CONTEXT.PROPERTY.TIME')}
            orientation={Orientation.vertical}
            class="ac-spacing-right-sm"
          >
            <span>{propertyTime}</span>
          </ac-text-group>
        )}
      </ac-flex>
    </ac-flex>
  );
};
