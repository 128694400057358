import { useMemo } from 'react';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { getCroContexts } from 'frontend-container/components/Menu/components/CroContext/service';
import { getProfileCentersContexts } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import { getPropertyContexts } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { getInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import {
  getUserUnitsDetails,
  UserUnitsDetails,
} from 'frontend-container/components/Prestep/getUserUnitsDetails';

import { SessionService } from '@ac/library-utils/dist/services';

export type UserSelectedUnits = {
  selectedCro?: Context;
  selectedProperty?: Context;
  selectedProfileCenter?: Context;
  unitDetails?: UserUnitsDetails;
};

export const useSelectedUserUnits = (): UserSelectedUnits => {
  const croContexts = getCroContexts();
  const profileCenterContexts = getProfileCentersContexts();
  const propertyContexts = getPropertyContexts();
  const { propertyId, centralReservationOfficeId, profileCenterId } =
    useSessionContextTypeIds();

  const selectedCro = useMemo(() => {
    return (
      croContexts.find(({ id }) => id === centralReservationOfficeId) ??
      getInitialContext(
        croContexts,
        SessionService.getCentralReservationOfficeId()
      )
    );
  }, [centralReservationOfficeId, croContexts]);

  const selectedProperty = useMemo(() => {
    return (
      propertyContexts.find(({ id }) => id === propertyId) ??
      getInitialContext(propertyContexts, SessionService.getPropertyId())
    );
  }, [propertyContexts, propertyId]);

  const selectedProfileCenter = useMemo(() => {
    return (
      profileCenterContexts.find(({ id }) => id === profileCenterId) ??
      getInitialContext(
        profileCenterContexts,
        SessionService.getProfileCenterId()
      )
    );
  }, [profileCenterContexts, profileCenterId]);

  const unitDetails = getUserUnitsDetails(
    propertyContexts,
    croContexts,
    profileCenterContexts
  );

  return {
    selectedCro,
    selectedProfileCenter,
    selectedProperty,
    unitDetails,
  };
};
