import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { isCroContextVisible } from 'frontend-container/components/Menu/components/CroContext';
import { isProfileCentersContextVisible } from 'frontend-container/components/Menu/components/ProfileCentersContext/isProfileCentersContextVisible';
import { isPropertyContextVisible } from 'frontend-container/components/Menu/components/PropertyContext';

export const getCurrentContextType = (): ContextType | undefined => {
  if (isPropertyContextVisible()) {
    return ContextType.PROPERTY;
  }

  if (isProfileCentersContextVisible()) {
    return ContextType.PROFILE_CENTER;
  }

  if (isCroContextVisible()) {
    return ContextType.CRO;
  }

  return;
};
