import {
  AccessSource,
  PermissionsConjunction,
} from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getPropertyContextData } from 'frontend-container/shared/businessContext/getBusinessContext';
import {
  MAC_SHORTCUT_PREFIX,
  WINDOWS_SHORTCUT_PREFIX,
} from 'frontend-container/shared/constants';

import {
  FeatureToggleName,
  GeneralPropertySettingsKeys,
  propertyPermissionKeys,
  ReservationsCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const reservationPermissions = propertyPermissionKeys.reservations;
export const frontDeskDashboardLink = `${acConfig.newFrontendUrls.reservations}/frontdesk`;
export const individualReservationFrontDeskDashboardLink = `${acConfig.newFrontendUrls.individualReservations}/frontdesk`;

export const houseStatusLink = `${acConfig.newFrontendUrls.reports}/house-status-dashboard`;
const housekeepingPermissions = propertyPermissionKeys.housekeeping;

const FRONT_DESK_MENU_ID = 'menu-frontdesk';

export const frontDeskMenu: MenuElement = {
  translation: 'MENU.FRONTDESK.TITLE',
  icon: IconName.frontdesk,
  id: FRONT_DESK_MENU_ID,
  items: [
    {
      get link(): string {
        const isReactFrontDeskDashboardEnabled = isFeatureToggleEnabled(
          FeatureToggleName.EmberToReactFrontDeskDashboardReactEnabled
        );

        return isReactFrontDeskDashboardEnabled
          ? individualReservationFrontDeskDashboardLink
          : frontDeskDashboardLink;
      },
      aliases: [
        `${acConfig.newFrontendUrls.reservations}/reservations/:id/check-in`,
        `${acConfig.newFrontendUrls.individualReservations}/frontdesk`,
      ],
      id: `${FRONT_DESK_MENU_ID}-dashboard`,
      code: 'FrontDeskDashboard',
      translation: 'MENU.FRONTDESK.ITEMS.DASHBOARD',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.dashboard.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}F`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}F`,
      keyCode: 70,
    },
    {
      link: houseStatusLink,
      code: 'HouseStatus',
      id: `${FRONT_DESK_MENU_ID}-house-status-dashboard`,
      translation: 'MENU.FRONTDESK.ITEMS.HOUSE_STATUS_DASHBOARD',
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}S`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}S`,
      keyCode: 83,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/room-planner`,
      code: 'RoomPlanner',
      translation: 'MENU.FRONTDESK.ITEMS.ROOM_PLANNER',
      id: `${FRONT_DESK_MENU_ID}-room-planner`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.dashboard.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.IsRoomPlannerActive,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}L`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}L`,
      keyCode: 76,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/rooms/assignment`,
      code: 'RoomAssignment',
      id: `${FRONT_DESK_MENU_ID}-room-assignment`,
      translation: 'MENU.FRONTDESK.ITEMS.ROOM_ASSIGNMENT',
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.viewReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.RoomAssignment,
          source: AccessSource.Property,
        },
      ],
      featureToggles: [
        {
          key: FeatureToggleName.RoomAssignmentDashboard,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}]`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}]`,
      keyCode: 221,
    },
    {
      link: `${acConfig.newFrontendUrls.reservations}/reservations/walk-in`,
      translation: 'MENU.FRONTDESK.ITEMS.WALK_IN',
      id: `${FRONT_DESK_MENU_ID}-walk-in`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.manageReservation,
            source: AccessSource.Property,
          },
          {
            key: reservationPermissions.reservation.checkInReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}/`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}/`,
      keyCode: 191,
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/queue-rooms`,
      translation: 'MENU.FRONTDESK.ITEMS.QUEUE_ROOMS',
      id: `${FRONT_DESK_MENU_ID}-queue-rooms`,
      permissionsConfiguration: {
        permissions: [
          {
            key: propertyPermissionKeys.queueRooms.viewDashboard,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.queueRooms,
          source: AccessSource.Property,
        },
      ],
    },
    {
      link: `${acConfig.newFrontendUrls.housekeeping}/floor-plan`,
      translation: 'MENU.FRONTDESK.ITEMS.FLOOR_PLAN',
      id: `${FRONT_DESK_MENU_ID}-floor-plan`,
      permissionsConfiguration: {
        permissions: [
          {
            key: housekeepingPermissions.rooms.view,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: GeneralPropertySettingsKeys.FloorPlan,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}2`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}2`,
      keyCode: 50,
    },
    {
      link: `${acConfig.newFrontendUrls.individualReservations}/guest-messages`,
      translation: 'MENU.FRONTDESK.ITEMS.GUEST_MESSAGES',
      id: `${FRONT_DESK_MENU_ID}-guest-messages`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.guestMessages.accessMessageCenter,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.GuestMessage,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}M`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}M`,
      keyCode: 77,
    },
    {
      link: `${acConfig.newFrontendUrls.individualReservations}/wake-up-calls`,
      translation: 'MENU.FRONTDESK.ITEMS.WAKE_UP_CALLS',
      id: `${FRONT_DESK_MENU_ID}-wake-up-calls`,
      permissionsConfiguration: {
        permissions: [
          {
            key: reservationPermissions.reservation.viewReservation,
            source: AccessSource.Property,
          },
        ],
        permissionsConjunction: PermissionsConjunction.And,
      },
      settings: [
        {
          key: ReservationsCustomerSettingsKeys.WakeUpCalls,
          source: AccessSource.Property,
        },
      ],
      keyboardShortcutWindows: `${WINDOWS_SHORTCUT_PREFIX}W`,
      keyboardShortcutMac: `${MAC_SHORTCUT_PREFIX}W`,
      keyCode: 87,
    },
  ],
};

const isFeatureToggleEnabled = (featureToggleName: string): boolean =>
  !!getPropertyContextData()?.featureToggles.find(
    (toggle) => toggle.featureName === featureToggleName
  )?.enabled;
