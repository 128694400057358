import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import {
  activitiesDashboardLink,
  activityReservationsIcon,
  activityReservationsTitle,
} from 'frontend-container/components/Menu/configuration/activityReservations';
import {
  cashieringDashboardLink,
  cashieringMenu,
} from 'frontend-container/components/Menu/configuration/cashiering';
import {
  frontDeskDashboardLink,
  frontDeskMenu,
  houseStatusLink,
  individualReservationFrontDeskDashboardLink,
} from 'frontend-container/components/Menu/configuration/frontdesk';
import { groupsDashboardLink } from 'frontend-container/components/Menu/configuration/groups';
import {
  housekeepingDashboardLink,
  housekeepingMenu,
} from 'frontend-container/components/Menu/configuration/housekeeping';
import {
  profilesDashboardLink,
  profilesMenu,
} from 'frontend-container/components/Menu/configuration/profiles';
import { reportsDashboardLink } from 'frontend-container/components/Menu/configuration/reports';
import {
  availabilityDetailedLink,
  createReservationLink,
  individualReservationsDashboardLink,
  reservationsAvailabilityLink,
  reservationsDashboardLink,
  reservationsMenu,
} from 'frontend-container/components/Menu/configuration/reservations';

import { FIQLOperators } from '@ac/library-api';

export const GLOBAL_SEARCH_MODAL_ID = 'global-search-modal-content';
export const OLD_RESERVATIONS_QUERY_PARAM = 'serializedKeyValuesFilters';
export const GLOBAL_SEARCH_HOTKEY_MAC = '⌘ K';
export const GLOBAL_SEARCH_HOTKEY_WINDOWS = 'Ctrl+K';

export const GLOBAL_SEARCH_RECENT_STORAGE_KEY =
  'global-search-recent-storage-key';

export const getSearchForNumberOptions = (
  isEmberToReactEnabled: boolean | undefined,
  isEmberToReactFrontDeskDashboardEnabled: boolean | undefined
): GlobalSearchMenuElementItem[] => [
  {
    id: 'search-for-number-front-desk-dashboard',
    adjustedId: 'search-for-number-front-desk-dashboard',
    icon: frontDeskMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_FRONT_DESK_DASHBOARD',
    badgeLabel: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.ROOM',
    link: isEmberToReactFrontDeskDashboardEnabled
      ? individualReservationFrontDeskDashboardLink
      : frontDeskDashboardLink,
    parentName: frontDeskMenu.translation,
    queryParams: {
      filterKey: 'roomNumber',
      isReservationCustomParam: true,
    },
  },
  {
    id: 'search-for-number-reservations-dashboard',
    adjustedId: 'search-for-number-reservations-dashboard',
    icon: reservationsMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_RESERVATION_DASHBOARD',
    badgeLabel: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.CONFIRMATION',
    link: isEmberToReactEnabled
      ? individualReservationsDashboardLink
      : reservationsDashboardLink,
    parentName: reservationsMenu.translation,
    queryParams: {
      filterKey: isEmberToReactEnabled ? undefined : 'confirmationNumber',
      isReservationCustomParam: !isEmberToReactEnabled,
      queryKey: isEmberToReactEnabled ? 'confirmationNumber' : undefined,
      underscore: isEmberToReactEnabled,
    },
  },
  {
    id: 'search-for-number-housekeeping-dashboard',
    adjustedId: 'search-for-number-housekeeping-dashboard',
    icon: housekeepingMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_HOUSEKEEPING_DASHBOARD',
    badgeLabel: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.ROOM',
    link: housekeepingDashboardLink,
    parentName: housekeepingMenu.translation,
    queryParams: {
      filterKey: 'roomNumbers',
    },
  },
  {
    id: 'search-for-number-cashiering-dashboard',
    adjustedId: 'search-for-number-cashiering-dashboard',
    icon: cashieringMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_CASHIERING_DASHBOARD',
    badgeLabel: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.ROOM',
    link: cashieringDashboardLink,
    parentName: cashieringMenu.translation,
    queryParams: {
      filterKey: 'reservationDetails.roomNumber',
      operator: FIQLOperators.equal,
      underscore: true,
    },
  },
];

export const getSearchForOptions = (
  isEmberToReactEnabled: boolean | undefined,
  isEmberToReactFrontDeskDashboardEnabled: boolean | undefined
): GlobalSearchMenuElementItem[] => [
  {
    id: 'search-for-reservations-dashboard',
    adjustedId: 'search-for-reservations-dashboard',
    icon: reservationsMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_RESERVATION_DASHBOARD',
    link: isEmberToReactEnabled
      ? individualReservationsDashboardLink
      : reservationsDashboardLink,
    parentName: reservationsMenu.translation,
    queryParams: {
      queryKey: 'query',
      underscore: isEmberToReactEnabled,
    },
  },
  {
    id: 'search-for-front-desk-dashboard',
    adjustedId: 'search-for-front-desk-dashboard',
    icon: frontDeskMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_FRONT_DESK_DASHBOARD',
    link: isEmberToReactFrontDeskDashboardEnabled
      ? individualReservationFrontDeskDashboardLink
      : frontDeskDashboardLink,
    parentName: frontDeskMenu.translation,
    queryParams: {
      queryKey: 'query',
    },
  },
  {
    id: 'search-for-cashiering-dashboard',
    adjustedId: 'search-for-cashiering-dashboard',
    icon: cashieringMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_CASHIERING_DASHBOARD',
    link: cashieringDashboardLink,
    parentName: cashieringMenu.translation,
    queryParams: {
      queryKey: 'query',
      underscore: true,
    },
  },
  {
    id: 'search-for-housekeeping-dashboard',
    adjustedId: 'search-for-housekeeping-dashboard',
    icon: housekeepingMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_HOUSEKEEPING_DASHBOARD',
    link: housekeepingDashboardLink,
    parentName: housekeepingMenu.translation,
    queryParams: {
      filterKey: 'fts',
    },
  },
  {
    id: 'search-for-activities-dashboard',
    adjustedId: 'search-for-activities-dashboard',
    icon: activityReservationsIcon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_ACTIVITIES_DASHBOARD',
    link: activitiesDashboardLink,
    parentName: activityReservationsTitle,
    queryParams: {
      queryKey: 'fullTextSearch',
    },
  },
  {
    id: 'search-for-profiles-dashboard',
    adjustedId: 'search-for-profiles-dashboard',
    icon: profilesMenu.icon,
    translation: 'MENU.GLOBAL_SEARCH.SEARCH_FOR.IN_PROFILE_DASHBOARD',
    link: profilesDashboardLink,
    parentName: profilesMenu.translation,
    queryParams: {
      queryKey: 'query',
    },
  },
];

export const popularLinks: string[] = [
  frontDeskDashboardLink,
  reservationsDashboardLink,
  individualReservationsDashboardLink,
  cashieringDashboardLink,
  groupsDashboardLink,
  reportsDashboardLink,
  housekeepingDashboardLink,
  reservationsAvailabilityLink,
  createReservationLink,
  houseStatusLink,
  availabilityDetailedLink,
];
