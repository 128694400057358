import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BreadcrumbsButton,
  BreadcrumbsButtonType,
} from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsButton';
import { BreadcrumbsMobileView } from 'frontend-container/components/Menu/components/Breadcrumbs/BreadcrumbsMobileView';
import { useBreadCrumbsOptionalSegmentDictionary } from 'frontend-container/components/Menu/components/Breadcrumbs/useBreadCrumbsOptionalSegmentDictionary';
import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { ContextSelectButton } from 'frontend-container/components/Menu/components/ContextSelectButton/ContextSelectButton';
import { ButtonContextOption } from 'frontend-container/components/Menu/components/ContextSelectButton/useContextMenuItems';
import { CRO_MENU_ID } from 'frontend-container/components/Menu/configuration/croStructure';
import {
  ORGANIZATION_STRUCTURE_MENU_ID,
  PROPERTY_DETAILS_MENU_ID,
} from 'frontend-container/components/Menu/configuration/organizationStructure';
import { PROFILE_CENTERS_MENU_ID } from 'frontend-container/components/Menu/configuration/profileCenters';
import { USERS_MENU_ID } from 'frontend-container/components/Menu/configuration/users';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import { getSortedMenuItemsFlat } from 'frontend-container/components/Menu/utils/getSortedMenuItems';
import { useRouterContext } from 'frontend-container/components/Router/context/context';

import {
  ResponsiveViewport,
  useViewportContext,
  ViewBreakpoint,
} from '@ac/react-infrastructure';

export const breadcrumbsUnitItemId = 'breadcrumbs-unit-button';

interface Props {
  menuItems: MenuElement[];
  buttonSelectOptions?: ButtonContextOption[];
  currentButtonOption?: ButtonContextOption;
  pageTitle?: string;
  currentModule?: MenuElement;
  selectedItem?: MenuElementItem;
}

export const Breadcrumbs = ({
  menuItems,
  buttonSelectOptions,
  currentButtonOption,
  currentModule,
  pageTitle,
  selectedItem,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const allAllowedMenuItems = menuItems;

  const unitDetails = useRouterContext((store) => store.unitDetails);
  const unit = useRouterContext((store) => store.currentUnit);

  const sortedMenuItems = useMemo((): MenuElement[] => {
    return getSortedMenuItemsFlat(allAllowedMenuItems, {
      customOrder: [
        USERS_MENU_ID,
        PROFILE_CENTERS_MENU_ID,
        CRO_MENU_ID,
        ORGANIZATION_STRUCTURE_MENU_ID,
        PROPERTY_DETAILS_MENU_ID,
      ],
    });
  }, [allAllowedMenuItems]);

  const {
    state: { responsiveBreakpoint },
  } = useViewportContext();

  const isMobileMenuHidden = responsiveBreakpoint > ViewBreakpoint.SM;

  const menuItemTranslation = t(currentModule?.translation ?? '');
  const selectedItemTranslation = t(selectedItem?.translation ?? '');

  const isContextButtonVisible =
    buttonSelectOptions && buttonSelectOptions.length >= 2;

  const isUnitBreadcrumbVisible = useMemo((): boolean => {
    switch (unit?.type) {
      case ContextType.PROPERTY: {
        return !unitDetails?.isSinglePropertyUser;
      }
      case ContextType.CRO: {
        return !unitDetails?.isSingleCroUser;
      }
      case ContextType.PROFILE_CENTER: {
        return !unitDetails?.isSingleProfileCenterUser;
      }

      default:
        return false;
    }
  }, [
    unit?.type,
    unitDetails?.isSinglePropertyUser,
    unitDetails?.isSingleCroUser,
    unitDetails?.isSingleProfileCenterUser,
  ]);

  const pathname = window.location.pathname;
  const search = window.location.search;
  const optionalSegment = useBreadCrumbsOptionalSegmentDictionary(
    pathname,
    search
  );

  const propertyCurrentModule =
    currentModule && unit?.type === ContextType.PROPERTY
      ? currentModule
      : undefined;

  return (
    <ResponsiveViewport>
      <ContextSelectButton
        buttonSelectOptions={buttonSelectOptions}
        currentButtonOption={currentButtonOption}
        showDivider={false}
        hideIcon
      />

      {!isMobileMenuHidden && (
        <BreadcrumbsMobileView
          isContextButtonVisible={isContextButtonVisible}
          isUnitBreadcrumbVisible={isUnitBreadcrumbVisible}
          menuItemName={menuItemTranslation}
          pageTitle={pageTitle}
          unitCode={unit?.code}
          menuItems={sortedMenuItems}
          subItems={currentModule?.items ?? []}
          selectedItemTranslation={selectedItemTranslation}
        />
      )}

      {isMobileMenuHidden && (
        <Fragment>
          {isUnitBreadcrumbVisible && (
            <BreadcrumbsButton
              content={unit?.code}
              showIcon={isContextButtonVisible}
              type={BreadcrumbsButtonType.context}
              id={breadcrumbsUnitItemId}
            />
          )}
          {menuItemTranslation && (
            <BreadcrumbsButton
              id="breadcrumbs-module-button"
              content={menuItemTranslation}
              showIcon={isContextButtonVisible || isUnitBreadcrumbVisible}
              type={BreadcrumbsButtonType.module}
              menuItems={sortedMenuItems}
              currentModule={propertyCurrentModule}
            />
          )}
          {optionalSegment && (
            <BreadcrumbsButton
              id="breadcrumbs-single-button"
              content={t(optionalSegment.title)}
              transparent={true}
              optionalLink={optionalSegment.link}
            />
          )}
        </Fragment>
      )}
    </ResponsiveViewport>
  );
};
