import { useCallback } from 'react';
import { ContextType } from 'frontend-container/components/Menu/components/Context';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { getCroContextById } from 'frontend-container/components/Menu/components/CroContext/service';
import { getProfileCenterContextById } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import {
  getPropertyContextById,
  getPropertyContexts,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { getInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { getCurrentContextType } from 'frontend-container/components/Router/utils/getCurrentContextType';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';

export const useInitializeContextForRouter = (): (() => Promise<void>) => {
  const { propertyId, centralReservationOfficeId, profileCenterId } =
    useSessionContextTypeIds();
  const setSelectedProperty = useRouterContext(
    (store) => store.setSelectedProperty
  );
  const setSelectedCro = useRouterContext((store) => store.setSelectedCro);
  const setSelectedProfileCenter = useRouterContext(
    (store) => store.setSelectedProfileCenter
  );
  const setCurrentUnit = useRouterContext((store) => store.setCurrentUnit);
  const setIsInitialized = useRouterContext((store) => store.setIsInitialized);

  const initialize = useCallback(async (): Promise<void> => {
    const tenantId = LoginService.authData()?.tenantId;

    if (!LoginService.isSuperUser() && !tenantId) {
      throw new Error('Tenant ID is undefined');
    }

    const contextType = getCurrentContextType();

    switch (contextType) {
      case ContextType.PROPERTY: {
        const context = isDefined(propertyId)
          ? getPropertyContextById(propertyId)
          : undefined;

        if (context) {
          setSelectedProperty(context);
          setCurrentUnit(context);
        }

        break;
      }
      case ContextType.CRO: {
        const context = isDefined(centralReservationOfficeId)
          ? getCroContextById(centralReservationOfficeId)
          : undefined;

        if (context) {
          setSelectedCro(context);
          setCurrentUnit(context);
        }

        break;
      }
      case ContextType.PROFILE_CENTER: {
        const context = isDefined(profileCenterId)
          ? getProfileCenterContextById(profileCenterId)
          : undefined;
        if (context) {
          setSelectedProfileCenter(context);
          setCurrentUnit(context);
        }

        break;
      }
      default: {
        const propertyContexts = getPropertyContexts();

        const context = getInitialContext(
          propertyContexts,
          SessionService.getPropertyId()
        );

        setSelectedProperty(context);
        setCurrentUnit(context);

        break;
      }
    }

    setIsInitialized(true);
  }, [
    centralReservationOfficeId,
    profileCenterId,
    propertyId,
    setCurrentUnit,
    setIsInitialized,
    setSelectedCro,
    setSelectedProfileCenter,
    setSelectedProperty,
  ]);

  return initialize;
};
