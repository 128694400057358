import { Context } from 'frontend-container/components/Menu/components/Context';

import {
  CentralReservationOfficeUserListItemDto,
  ProfileCenterDto,
  UnitAssignmentListItem,
} from '@ac/library-api';
import { isDefined } from '@ac/library-utils/dist/utils';

export interface UserUnitsDetails {
  isSingleCroUser: boolean;
  isMultiCroUser: boolean;
  isSinglePropertyUser: boolean;
  isMultiPropertyUser: boolean;
  isSingleProfileCenterUser: boolean;
  isMultiProfileCenterUser: boolean;
  isUserWithoutProperties: boolean;
  isUserWithoutCroUnits: boolean;
  isUserWithoutProfileCenterUnits: boolean;
}

export const getUserUnitsDetails = (
  units?: UnitAssignmentListItem[] | Context[],
  croUnits?: CentralReservationOfficeUserListItemDto[] | Context[],
  profileCenterUnits?: ProfileCenterDto[] | Context[]
): UserUnitsDetails => {
  return {
    isSingleCroUser: isDefined(croUnits) && croUnits.length === 1,
    isMultiCroUser: isDefined(croUnits) && croUnits.length > 1,
    isSinglePropertyUser: isDefined(units) && units.length === 1,
    isMultiPropertyUser: isDefined(units) && units.length > 1,
    isSingleProfileCenterUser:
      isDefined(profileCenterUnits) && profileCenterUnits.length === 1,
    isMultiProfileCenterUser:
      isDefined(profileCenterUnits) && profileCenterUnits.length > 1,
    isUserWithoutProperties: !isDefined(units) || !units.length,
    isUserWithoutCroUnits: !isDefined(croUnits) || !croUnits.length,
    isUserWithoutProfileCenterUnits:
      !isDefined(profileCenterUnits) || !profileCenterUnits.length,
  };
};
