import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginLogoutLayoutBase } from 'frontend-container/components/LoginLogout/LoginLogoutLayoutBase';
import { getLoginBrandingFromStorage } from 'frontend-container/components/Menu/components/Branding/brandingLocalStorage';
import { updateFavicon } from 'frontend-container/components/Menu/components/Branding/updateFavicon';
import { popStoredURL } from 'frontend-container/utils/loginService/logoutService';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { withTranslationsReady } from '@ac/react-infrastructure';

const Logout = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    loginBackgroundUrl,
    loginLogoUrl,
    loginBackgroundId,
    loginLogoId,
    loginFaviconData,
  } = getLoginBrandingFromStorage() ?? {};

  const backgroundImageUrl = loginBackgroundUrl?.concat(
    loginBackgroundId ? `?${loginBackgroundId}` : ''
  );
  const customerLogoUrl = loginLogoUrl?.concat(
    loginLogoId ? `?${loginLogoId}` : ''
  );

  useEffect(() => {
    if (loginFaviconData && typeof loginFaviconData === 'string') {
      try {
        fetch(loginFaviconData)
          .then((res) => res.blob())
          .then((res) => updateFavicon({ favicon: res }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  });

  return (
    <LoginLogoutLayoutBase
      message={t('LOGIN_LOGOUT.LOGOUT.MESSAGE')}
      onBackToLoginClick={(): string =>
        (window.location.href = `${acConfig.containerFrontendUrl}${
          popStoredURL() || ''
        }`)
      }
      backgroundImageUrl={backgroundImageUrl}
      customerLogoUrl={customerLogoUrl}
    />
  );
};

const LogoutWithTranslations = withTranslationsReady(Logout);

export { LogoutWithTranslations as Logout };
