import { acConfig } from '@ac/library-utils/dist/declarations';

export const isTravelAgentCommissionsModule = (
  pathname: string = window.location.pathname
): boolean => {
  return pathname?.startsWith(
    acConfig.newFrontendUrls.travelAgentCommissions ?? ''
  );
};

export default {
  isTravelAgentCommissionsModule,
};
