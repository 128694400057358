import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { GlobalSearchMenuElementItem } from 'frontend-container/components/Menu/components/GlobalSearchModal/types';
import { mapMenuElementsToGlobalSearchElement } from 'frontend-container/components/Menu/components/GlobalSearchModal/utils/mapMenuElementsToGlobalSearchElement';
import {
  getAllowedCentralReservationOfficeMenu,
  getAllowedProfileCenterMenu,
  getAllowedPropertyMenu,
} from 'frontend-container/components/Menu/configuration';
import { getConfigurationMenuInMainApplication } from 'frontend-container/components/Menu/configuration/configuration';
import {
  ContextOption,
  getCurrentContextOption,
} from 'frontend-container/components/Menu/utils/getCurrentContext';
import { useRouterContext } from 'frontend-container/components/Router/context/context';

export interface AllowedItems {
  globalSearchItems: GlobalSearchMenuElementItem[];
}

export const useAllowedItems = (): AllowedItems => {
  const { t } = useTranslation();
  const currentContext = getCurrentContextOption();
  const accessConfiguration = useRouterContext(
    (store) => store.allAccessConfiguration
  );
  const selectedCro = useRouterContext((store) => store.selectedCro);
  const selectedProperty = useRouterContext((store) => store.selectedProperty);
  const selectedProfileCenter = useRouterContext(
    (store) => store.selectedProfileCenter
  );

  const propertySearchItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    if (!selectedProperty) {
      return [];
    }
    const propertyItems = getAllowedPropertyMenu(accessConfiguration);

    const badgeText =
      currentContext !== ContextOption.property
        ? selectedProperty.code
        : undefined;

    return mapMenuElementsToGlobalSearchElement(propertyItems, t, badgeText);
  }, [accessConfiguration, currentContext, selectedProperty, t]);

  const profileCenterSearchItems: GlobalSearchMenuElementItem[] =
    useMemo(() => {
      if (!selectedProfileCenter) {
        return [];
      }
      const allowedProfileCenterItems =
        getAllowedProfileCenterMenu(accessConfiguration);

      const badgeText =
        currentContext !== ContextOption.profileCenter
          ? selectedProfileCenter?.code
          : undefined;

      return mapMenuElementsToGlobalSearchElement(
        allowedProfileCenterItems,
        t,
        badgeText
      );
    }, [accessConfiguration, currentContext, selectedProfileCenter, t]);

  const croSearchItems: GlobalSearchMenuElementItem[] = useMemo(() => {
    if (!selectedCro) {
      return [];
    }
    const allowedCroItems =
      getAllowedCentralReservationOfficeMenu(accessConfiguration);

    const badgeText =
      currentContext !== ContextOption.cro ? selectedCro?.code : undefined;

    return mapMenuElementsToGlobalSearchElement(allowedCroItems, t, badgeText);
  }, [accessConfiguration, currentContext, selectedCro, t]);

  const configurationSearchItems: GlobalSearchMenuElementItem[] =
    useMemo(() => {
      const allowedConfigurationItems = getAllowedMenuItems(
        [getConfigurationMenuInMainApplication()],
        accessConfiguration
      );

      return mapMenuElementsToGlobalSearchElement(allowedConfigurationItems, t);
    }, [accessConfiguration, t]);

  return {
    globalSearchItems: [
      ...propertySearchItems,
      ...profileCenterSearchItems,
      ...croSearchItems,
      ...configurationSearchItems,
    ],
  };
};
