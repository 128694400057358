import { createContext } from 'react';
import { GlobalSearchModalPresenterStore } from 'frontend-container/components/Menu/components/GlobalSearchModal/presenter/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const GlobalSearchModalPresenterContext = createContext<
  GlobalSearchModalPresenterStore | undefined
>(undefined);

export const useGlobalSearchModalPresenterContext =
  (): GlobalSearchModalPresenterStore =>
    useDefinedContext(GlobalSearchModalPresenterContext);
