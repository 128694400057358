export interface ActivityTrackerRequestData {
  accessToken: string;
  expiresAt: number;
  identityServerOffset: number;
  apiUrl: string;
  tenantId?: string;
  propertyId?: string;
  workstationId?: string;
}

export interface ActivityTrackerPageIdentifier {
  pageIdentifier: string;
}

export enum ActivityTrackerCommand {
  NewPage = 'NewPage',
  RequestInfo = 'RequestInfo',
  Stop = 'Stop',
}

export type ActivityTrackerData =
  | ActivityTrackerNewPageData
  | ActivityTrackerRequestInfoData
  | ActivityTrackerStopData;

type ActivityTrackerNewPageData = {
  command: ActivityTrackerCommand.NewPage;
} & ActivityTrackerPageIdentifier &
  ActivityTrackerRequestData;

type ActivityTrackerRequestInfoData = {
  command: ActivityTrackerCommand.RequestInfo;
} & ActivityTrackerRequestData;

type ActivityTrackerStopData = {
  command: ActivityTrackerCommand.Stop;
} & ActivityTrackerPageIdentifier;
