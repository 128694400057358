import i18n from 'frontend-container/i18n';

import {
  AlignItems,
  ButtonPattern,
  DividerColor,
  FlexDirection,
  TextColor,
  TextSize,
} from '@ac/web-components';

import daylightBackground from './assets/aurora-sm.jpg';
import daylightLogo from './assets/daylight-logo.svg';

import './LoginLogoutLayoutBase.scss';

interface Props {
  message: string;
  backgroundImageUrl?: string;
  customerLogoUrl?: string;
  onBackToLoginClick: () => void;
}

export const LoginLogoutLayoutBase = ({
  message,
  onBackToLoginClick,
  backgroundImageUrl,
  customerLogoUrl,
}: Props): JSX.Element => (
  <ac-flex direction={FlexDirection.column} class="login-logout-page">
    <img
      className="login-logout-image"
      src={backgroundImageUrl || daylightBackground}
    />
    <ac-flex
      direction={FlexDirection.column}
      alignItems={AlignItems.center}
      class="login-logout-body"
    >
      <ac-flex
        class="ac-spacing-vertical-lg"
        direction={FlexDirection.column}
        alignItems={AlignItems.center}
      >
        {customerLogoUrl && (
          <>
            <img src={customerLogoUrl} className="branding-logo" />

            <div className="width-percentage-100 border-box ac-padding-horizontal-lg">
              <ac-divider color={DividerColor.lightGray} />
            </div>
          </>
        )}

        <img data-custom-selector="ac-logo" src={daylightLogo} />
      </ac-flex>

      <ac-text
        data-test-selector="page-message"
        class="center"
        size={TextSize.h3}
        color={TextColor.gray}
      >
        {message}
      </ac-text>

      <div>
        <ac-button
          pattern={ButtonPattern.tertiary}
          onClick={onBackToLoginClick}
          data-test-selector="back-to-login"
          class="ac-spacing-top-lg ac-spacing-bottom-sm"
        >
          <ac-button-content text={i18n.t('LOGIN_LOGOUT.BACK')} />
        </ac-button>
      </div>
    </ac-flex>
  </ac-flex>
);
