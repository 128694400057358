import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { UserUnitsDetails } from 'frontend-container/components/Prestep/getUserUnitsDetails';
import {
  getDefaultRouterState,
  RouterState,
} from 'frontend-container/components/Router/context/state';

import { createStoreManager, StoreManager } from '@ac/react-infrastructure';

export interface RouterStore extends RouterState {
  setSelectedProperty: (context?: Context) => void;
  setSelectedCro: (context?: Context) => void;
  setSelectedProfileCenter: (context?: Context) => void;
  setUserMenuElements: (menuElements: MenuElement[]) => void;
  setLocation: (location: string) => void;
  setCurrentUnit: (unit?: Context) => void;
  setAllAccessConfiguration: (access: AllAccessConfiguration) => void;
}

export type RouterMutableStore = StoreManager<RouterStore>;

export const createRouterStore = (): RouterMutableStore =>
  createStoreManager((set) => ({
    ...getDefaultRouterState(),
    setSelectedCro: (selectedCro?: Context) =>
      set({
        selectedCro,
      }),
    setSelectedProfileCenter: (selectedProfileCenter?: Context) =>
      set({
        selectedProfileCenter,
      }),
    setSelectedProperty: (selectedProperty?: Context) =>
      set({
        selectedProperty,
      }),
    setAllAccessConfiguration: (
      allAccessConfiguration: AllAccessConfiguration
    ) =>
      set({
        allAccessConfiguration,
      }),
    setLocation: (locationRef: string) =>
      set({
        locationRef,
      }),
    setUserMenuElements: (userMenuElements: MenuElement[]) =>
      set({
        userMenuElements: [...userMenuElements],
      }),
    setUnitDetails: (unitDetails: UserUnitsDetails) =>
      set({
        unitDetails,
      }),
    setCurrentUnit: (currentUnit: Context) =>
      set({
        currentUnit,
      }),
  }));
