import { createContext } from 'react';
import { MegaMenuPresenterStore } from 'frontend-container/components/Menu/components/MegaMenu/presenter/store';

import { useDefinedContext } from '@ac/react-infrastructure';

export const MegaMenuPresenterContext = createContext<
  MegaMenuPresenterStore | undefined
>(undefined);

export const useMegaMenuPresenterContext = (): MegaMenuPresenterStore =>
  useDefinedContext(MegaMenuPresenterContext);
