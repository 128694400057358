import { PropsWithChildren } from 'react';
import { RouterContext } from 'frontend-container/components/Router/context/context';
import { createRouterStore } from 'frontend-container/components/Router/context/store';

export const RouterContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = createRouterStore();

  return (
    <RouterContext.Provider value={store}>
      {props.children}
    </RouterContext.Provider>
  );
};
