import { MEGA_MENU_LEFT_ORDER } from 'frontend-container/components/Menu/components/MegaMenu/megaMenuConstants';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { isDefined } from '@ac/library-utils/dist/utils';

interface SortedMenuOptions {
  customOrder?: string[];
}

export const getSortedMenuItems = (menuItems: MenuElement[]): MenuElement[][] =>
  MEGA_MENU_LEFT_ORDER.map((orderArray) => {
    const orderedElements = orderArray
      .map((elementId) => {
        const item =
          menuItems.find((menuItem) => menuItem.id === elementId) || undefined;

        return item;
      })
      .filter((el) => !!el) as MenuElement[];

    return orderedElements || [];
  }).filter((el) => el.length);

export const getSortedMenuItemsFlat = (
  menuItems: MenuElement[],
  options?: SortedMenuOptions
): MenuElement[] => {
  const menuItemsSorted = getSortedMenuItems(menuItems);
  const menuItemsSortedFlat = menuItemsSorted.flat();

  const menuItemsWithCustomOrder = options?.customOrder
    ? options.customOrder
        .map((id) => menuItems.find((menuItem) => menuItem.id === id))
        .filter(isDefined)
    : [];

  if (menuItemsWithCustomOrder.length) {
    menuItemsSortedFlat.push(...menuItemsWithCustomOrder);
  }

  if (menuItemsSortedFlat.length) {
    return menuItemsSortedFlat;
  }

  return menuItems;
};
