import { getAllAccessConfiguration } from 'frontend-container/components/Menu/authorization/accessConfiguration';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context';
import { getCroContextById } from 'frontend-container/components/Menu/components/CroContext/service';
import { getProfileCenterContextById } from 'frontend-container/components/Menu/components/ProfileCentersContext/service';
import {
  getPropertyContextById,
  getPropertyContexts,
} from 'frontend-container/components/Menu/components/PropertyContext/service';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getInitialContext } from 'frontend-container/components/Menu/utils/selectInitialContext';
import { UserUnitsDetails } from 'frontend-container/components/Prestep/getUserUnitsDetails';
import { getCurrentContextType } from 'frontend-container/components/Router/utils/getCurrentContextType';

import { SessionService } from '@ac/library-utils/dist/services';
import { isDefined } from '@ac/library-utils/dist/utils';

export interface RouterState {
  allAccessConfiguration: AllAccessConfiguration;
  locationRef: string;
  userMenuElements: MenuElement[];
  selectedCro?: Context;
  selectedProperty?: Context;
  selectedProfileCenter?: Context;
  currentUnit?: Context;
  unitDetails?: UserUnitsDetails;
}

export const getDefaultRouterState = (): RouterState => {
  const propertyId = SessionService.getPropertyId();
  const profileCenterId = SessionService.getProfileCenterId();
  const centralReservationOfficeId =
    SessionService.getCentralReservationOfficeId();
  const propertyContexts = getPropertyContexts();

  const propertyContext = isDefined(propertyId)
    ? getPropertyContextById(propertyId)
    : getInitialContext(propertyContexts, SessionService.getPropertyId());

  const croContext = isDefined(centralReservationOfficeId)
    ? getCroContextById(centralReservationOfficeId)
    : undefined;

  const profileCenterContext = isDefined(profileCenterId)
    ? getProfileCenterContextById(profileCenterId)
    : undefined;

  const currentUnit = {
    [ContextType.CRO]: croContext,
    [ContextType.PROFILE_CENTER]: profileCenterContext,
    [ContextType.PROPERTY]: propertyContext,
  };

  const currentContextType = getCurrentContextType();
  const allAccessConfiguration = getAllAccessConfiguration();

  return {
    allAccessConfiguration,
    locationRef: window.location.href,
    userMenuElements: [],
    selectedCro: croContext,
    selectedProperty: propertyContext,
    selectedProfileCenter: profileCenterContext,
    currentUnit: currentContextType
      ? currentUnit[currentContextType]
      : undefined,
    unitDetails: undefined,
  };
};
