import { getFullMenu } from 'frontend-container/components/Menu/configuration';
import {
  MenuElement,
  MenuElementItem,
} from 'frontend-container/components/Menu/types';
import i18next from 'i18next';

import { updateDocumentTitle } from '@ac/library-utils/dist/utils';

export interface Route {
  moduleTranslation: string;
  link: string;
  routeTranslation: string;
}

export const defaultDocumentTitle = 'Shiji Platform';

const registeredDocumentTitles = new Map<string, string>();

const initializedRoutes: Route[] = [];

const getRoutes = (): Route[] => {
  if (initializedRoutes.length === 0) {
    initializeRoutes();
  }

  return initializedRoutes;
};

const initializeRoutes = (): void => {
  const menu = getFullMenu();

  menu.forEach((element) => {
    const reversedItemsOrder = [...element.items].reverse();
    reversedItemsOrder.forEach((item) => {
      initializedRoutes.push(getRouteObject(element, item));
      if (item.aliases?.length) {
        initializedRoutes.push(
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...item.aliases?.map((alias) => getRouteObject(element, item, alias))
        );
      }
    });
  });
};

const getRouteObject = (
  menuElement: MenuElement,
  menuItem: MenuElementItem,
  alias?: string
): Route => ({
  moduleTranslation: menuElement.translation,
  link: alias || menuItem.link,
  routeTranslation: menuItem.translation,
});

export const removeRoutePathParamsFromLink = (
  route: Route,
  link: string
): string => {
  let routeLink = route.link;

  while (routeLink.includes('/:')) {
    const pathParamIndex = routeLink.indexOf('/:');
    const routeEndOfPathParam = routeLink.indexOf('/', pathParamIndex + 1);
    const linkEndOfPathParam = link.indexOf('/', pathParamIndex + 1);
    link =
      link.slice(0, pathParamIndex) +
      link.slice(linkEndOfPathParam > -1 ? linkEndOfPathParam : link.length);
    routeLink =
      routeLink.slice(0, pathParamIndex) +
      routeLink.slice(
        routeEndOfPathParam > -1 ? routeEndOfPathParam : routeLink.length
      );
  }

  return link;
};

export const compareRouteLinkWithPathname = (
  route: Route,
  pathname: string
): boolean => {
  const routeLinkWithoutPathParam = removeRoutePathParamsFromLink(
    route,
    route.link
  );
  const linkWithoutPathParam = removeRoutePathParamsFromLink(route, pathname);

  return routeLinkWithoutPathParam === linkWithoutPathParam;
};

const findCurrentRoute = (): Route | undefined => {
  const routes = getRoutes();
  const pathname = window.location.pathname;
  const pathnameWithSearch = pathname + window.location.search;

  return (
    routes.find((route) =>
      compareRouteLinkWithPathname(route, pathnameWithSearch)
    ) ||
    routes.find((route) => compareRouteLinkWithPathname(route, pathname)) ||
    routes.find((route) => pathname.startsWith(route.link))
  );
};

export const getRouteTitle = (defaultTitle = defaultDocumentTitle): string => {
  const currentRoute = findCurrentRoute();

  if (currentRoute) {
    return i18next.t(
      currentRoute.routeTranslation || currentRoute.moduleTranslation
    );
  }

  return defaultTitle;
};

export const getModuleRouteTitle = (
  defaultTitle = defaultDocumentTitle
): string => {
  const currentRoute = findCurrentRoute();

  if (currentRoute) {
    return `${i18next.t(currentRoute.moduleTranslation)} - ${i18next.t(
      currentRoute.routeTranslation
    )}`;
  }

  return defaultTitle;
};

export const getRegisteredDocumentTitle = (): string | undefined => {
  const pathname = window.location.pathname;

  if (registeredDocumentTitles.has(pathname)) {
    return registeredDocumentTitles.get(pathname);
  }

  return undefined;
};

export const registerDocumentTitle = (url: string, title: string): void => {
  const parsedUrl = new URL(url);
  const route = parsedUrl.pathname + parsedUrl.search;

  registeredDocumentTitles.set(route, title);

  updateDocumentTitle(title);
};
