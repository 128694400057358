import { GlobalSearchModalContent } from 'frontend-container/components/Menu/components/GlobalSearchModal/GlobalSearchModalContent';
import { useGlobalSearchModalPresenterContext } from 'frontend-container/components/Menu/components/GlobalSearchModal/presenter/context';

export const GlobalSearchModal = (): JSX.Element | null => {
  const presenter = useGlobalSearchModalPresenterContext();

  return presenter.state.isVisible ? (
    <GlobalSearchModalContent presenter={presenter} />
  ) : null;
};
