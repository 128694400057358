import { GlobalSearchModalPresenterContext } from 'frontend-container/components/Menu/components/GlobalSearchModal/presenter/context';

import {
  PropsWithChildren,
  useModalPresenterStore,
} from '@ac/react-infrastructure';

export const GlobalSearchModalPresenterContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useModalPresenterStore<undefined, undefined>();

  return (
    <GlobalSearchModalPresenterContext.Provider value={store}>
      {props.children}
    </GlobalSearchModalPresenterContext.Provider>
  );
};
