import { propertyLandingPagePathname } from 'frontend-container/components/LandingPage';
import {
  croLandingPagePathname,
  landingPageDestination,
  profileCenterLandingPagePathname,
} from 'frontend-container/components/LandingPage/location';
import {
  Context,
  ContextType,
} from 'frontend-container/components/Menu/components/Context';
import { setSessionContext } from 'frontend-container/components/Menu/components/Context/setContext';
import { useSessionContextTypeIds } from 'frontend-container/components/Menu/components/Context/useSessionContextTypeIds';
import { getNewUrlForHousekeepingModule } from 'frontend-container/components/Menu/components/PropertyContext/getNewUrlForHousekeepingModule';
import { getNewUrlForReservationsModuleIfNeeded } from 'frontend-container/components/Menu/components/PropertyContext/getNewUrlForReservationsModuleIfNeeded';
import { reservationsMenu } from 'frontend-container/components/Menu/configuration/reservations';
import { getActiveContextOption } from 'frontend-container/components/Menu/utils/getActiveContextOption';
import { ContextOption } from 'frontend-container/components/Menu/utils/getCurrentContext';
import { isErrorPage } from 'frontend-container/components/Menu/utils/isErrorPage';
import { isCashieringBillingModule } from 'frontend-container/components/Menu/utils/modules/cashiering';
import { isHousekeepingModule } from 'frontend-container/components/Menu/utils/modules/housekeeping';
import { profileCentersPath } from 'frontend-container/components/Menu/utils/modules/profileCenters';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { PresenterResultType } from 'frontend-container/publicApi';
import { selectUnitInBusinessContext } from 'frontend-container/shared/businessContext/selectUnitInBusinessContext';
import { navigateToPage } from 'frontend-container/shared/navigation/navigateToPage';
import { findRegionData } from 'frontend-container/utils/region/findRegion';
import {
  getOriginForNewRegion,
  getUrlForNewRegion,
  getUrlWithContextParam,
} from 'frontend-container/utils/region/getUrlForNewRegion';
import { redirectOnCroSelectIfContextRegionDiffer } from 'frontend-container/utils/region/redirectOnCroSelectIfContextRegionDiffer';

import { acConfig } from '@ac/library-utils/dist/declarations';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';
import {
  getCurrentRegionCode,
  getGlobalRegionCode,
} from '@ac/library-utils/dist/utils/multi-region';

export const useSelectContext = (): ((
  context: Context,
  discardUnsavedChanges?: boolean
) => void) => {
  const selected = useRouterContext((store) => store.currentUnit);
  const setCurrentUnit = useRouterContext((store) => store.setCurrentUnit);
  const setSelectedProfileCenter = useRouterContext(
    (store) => store.setSelectedProfileCenter
  );
  const setSelectedCro = useRouterContext((store) => store.setSelectedCro);
  const setSelectedProperty = useRouterContext(
    (store) => store.setSelectedProperty
  );

  const activeContextOption = getActiveContextOption();

  const { propertyId } = useSessionContextTypeIds();

  const handleSelectProperty = async (
    context: Context,
    discardUnsavedChanges = false
  ): Promise<void> => {
    if (
      selected?.id === context.id &&
      activeContextOption !== ContextOption.configuration
    ) {
      return;
    }

    if (
      !discardUnsavedChanges &&
      window.ACP?.container?.hasUnsavedChanges?.()
    ) {
      const modal = window.ACP?.container?.modals?.unsavedChanges;
      const isUnsavedChangesModalVisible = modal?.isVisible ?? false;

      if (!isUnsavedChangesModalVisible) {
        const result = await modal?.show();

        if (result?.type === PresenterResultType.Confirmed) {
          await handleSelectProperty(context, true);
        } else {
          return;
        }
      }
    }

    const currentPropertyId = SessionService.getPropertyId();
    SessionService.setCashierNumber(undefined);
    SessionService.setPropertyCashierNumber(currentPropertyId, undefined);

    setSessionContext(context);
    setSelectedProperty(context);

    const currentRegionCode = getCurrentRegionCode();
    const optionRegionCode = context.regionCode ?? currentRegionCode;

    if (optionRegionCode !== currentRegionCode) {
      const region = findRegionData(optionRegionCode ?? getGlobalRegionCode());

      const originForNewRegion = getOriginForNewRegion(
        region?.code ?? '',
        region?.domain ?? ''
      );

      if (isHousekeepingModule() && context.id) {
        const param = getNewUrlForHousekeepingModule(
          context.id,
          propertyLandingPagePathname,
          originForNewRegion
        );

        return window.location.replace(
          `${getUrlWithContextParam(param)}?${landingPageDestination}=${
            window.location.pathname
          }`
        );
      }

      const newReservationsUrl = getNewUrlForReservationsModuleIfNeeded(
        window.location,
        originForNewRegion
      );
      if (newReservationsUrl) {
        return window.location.replace(
          getUrlWithContextParam(newReservationsUrl)
        );
      }

      return window.location.replace(
        getUrlForNewRegion(region?.code ?? '', region?.domain ?? '', context)
      );
    }

    if (activeContextOption !== ContextOption.property) {
      return window.location.replace(propertyLandingPagePathname);
    }

    if (isCashieringBillingModule()) {
      const newUrlForCashieringModule = `${propertyLandingPagePathname}${acConfig.newFrontendUrls.cashiering}/dashboard`;

      return window.location.replace(
        `${newUrlForCashieringModule}?${landingPageDestination}=${acConfig.newFrontendUrls.cashiering}/dashboard`
      );
    }

    if (isHousekeepingModule() && context.id) {
      return window.location.replace(
        `${getNewUrlForHousekeepingModule(
          context.id,
          propertyLandingPagePathname,
          window.location.origin
        )}?${landingPageDestination}=${window.location.pathname}`
      );
    }

    const newReservationsUrl = getNewUrlForReservationsModuleIfNeeded(
      window.location
    );
    if (newReservationsUrl) {
      const reservationsDashboardPath =
        reservationsMenu.items.find(
          ({ code }) => code === 'ReservationDashboard'
        )?.link ?? '';

      return window.location.replace(
        `${propertyLandingPagePathname}?${landingPageDestination}=${reservationsDashboardPath}`
      );
    }

    if (window.location.search) {
      return window.location.replace(
        `${propertyLandingPagePathname}?${landingPageDestination}=${window.location.pathname}`
      );
    }

    return window.location.replace(
      `${propertyLandingPagePathname}?${landingPageDestination}=${window.location.pathname}`
    );
  };

  const handleSelectCro = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSessionContext(context);
    setCurrentUnit(context);
    setSelectedCro(context);

    const isRedirectedToNewCroRegion =
      redirectOnCroSelectIfContextRegionDiffer(context);

    if (!isRedirectedToNewCroRegion && tenantId) {
      history.replaceState({}, '', croLandingPagePathname);

      void selectUnitInBusinessContext({
        tenantId,
        centralReservationOfficeId: context.id,
        propertyId,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', croLandingPagePathname);
      }
    }

    navigateToPage(croLandingPagePathname);
  };

  const handleSelectProfileCenter = (context: Context): void => {
    const tenantId = LoginService.authData()?.tenantId;

    setSessionContext(context);
    setCurrentUnit(context);
    setSelectedProfileCenter(context);

    if (tenantId) {
      history.replaceState({}, '', profileCentersPath);

      void selectUnitInBusinessContext({
        tenantId,
        profileCenterId: context.id,
      });

      if (isErrorPage()) {
        history.replaceState({}, '', profileCentersPath);
      }
    }

    navigateToPage(profileCenterLandingPagePathname);
  };

  const handleSelect = (
    option: Context,
    discardUnsavedChanges = false
  ): Promise<void> | void => {
    switch (option.type) {
      case ContextType.CRO: {
        return handleSelectCro(option);
      }
      case ContextType.PROPERTY: {
        return handleSelectProperty(option, discardUnsavedChanges);
      }
      case ContextType.PROFILE_CENTER: {
        return handleSelectProfileCenter(option);
      }
      default: {
        throw new Error('Context not defined');
      }
    }
  };

  return handleSelect;
};
