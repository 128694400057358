import { Branding } from 'frontend-container/shared/branding/loadBranding';

const DEFAULT_FAVICON_LIGHT_HREF =
  '/assets/favicon-light-mode/favicon-32x32.png';
const DEFAULT_LG_FAVICON_LIGHT_HREF =
  '/assets/favicon-light-mode/apple-touch-icon.png';
const DEFAULT_FAVICON_DARK_HREF = '/assets/favicon-dark-mode/favicon-32x32.png';
const DEFAULT_LG_FAVICON_DARK_HREF =
  '/assets/favicon-dark-mode/apple-touch-icon.png';

const favicon = document.querySelector('#favicon');
const faviconLg = document.querySelector('#favicon-lg');

export const updateFavicon = (branding?: Branding): void => {
  if (branding?.favicon) {
    const faviconUrl = URL.createObjectURL(branding?.favicon);
    favicon?.setAttribute('href', faviconUrl);
    faviconLg?.setAttribute('href', faviconUrl);

    return;
  }

  const preferDarkScheme =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;

  if (preferDarkScheme) {
    favicon?.setAttribute('href', DEFAULT_FAVICON_DARK_HREF);
    faviconLg?.setAttribute('href', DEFAULT_LG_FAVICON_DARK_HREF);
  } else {
    favicon?.setAttribute('href', DEFAULT_FAVICON_LIGHT_HREF);
    faviconLg?.setAttribute('href', DEFAULT_LG_FAVICON_LIGHT_HREF);
  }
};
