import { useTranslation } from 'react-i18next';
import { ContextComponent } from 'frontend-container/components/Menu/components/Context';
import { useProfileCentersDateTime } from 'frontend-container/components/Menu/components/ProfileCentersContext/useProfileCentersDateTime';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { useSelectContext } from 'frontend-container/components/Router/utils/useSelectContext';

interface Props {
  isContextSwitchHidden?: boolean;
  triggerId?: string;
}

export const ProfileCentersContext = ({
  isContextSwitchHidden,
  triggerId,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const isRouterInitialized = useRouterContext((store) => store.isInitialized);
  const selectedProfileCenter = useRouterContext(
    (store) => store.selectedProfileCenter
  );
  const onSelect = useSelectContext();

  return !isRouterInitialized ? (
    <ac-loader-covering />
  ) : (
    <ContextComponent
      selectedContext={selectedProfileCenter}
      onSelect={onSelect}
      useDateTime={useProfileCentersDateTime}
      dateLabel={t('MENU.CONTEXT.PROFILE_CENTERS.DATE')}
      timeLabel={t('MENU.CONTEXT.PROFILE_CENTERS.TIME')}
      triggerId={triggerId}
      isContextSwitchHidden={isContextSwitchHidden}
    />
  );
};
