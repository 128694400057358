import { getAllowedMenuItems } from 'frontend-container/components/Menu/authorization/getAllowedMenuItems';
import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { getActivityReservationsMenu } from 'frontend-container/components/Menu/configuration/activityReservations';
import { campaignManagementMenu } from 'frontend-container/components/Menu/configuration/campaignManagement';
import { centralAvailabilityInquiryMenu } from 'frontend-container/components/Menu/configuration/centralAvailabilityInquiry';
import { croStructureMenu } from 'frontend-container/components/Menu/configuration/croStructure';
import { getGolfMenu } from 'frontend-container/components/Menu/configuration/golf';
import { inquiryMenu } from 'frontend-container/components/Menu/configuration/inquiry';
import {
  configurationProfileCentersMenu,
  profileCenterMenu,
} from 'frontend-container/components/Menu/configuration/profileCenters';
import { propertyDetailsMenu } from 'frontend-container/components/Menu/configuration/propertyDetails';
import { propertyUsers } from 'frontend-container/components/Menu/configuration/propertyUsers';
import { rateManagerMenu } from 'frontend-container/components/Menu/configuration/rates';
import { reservationsLookupMenu } from 'frontend-container/components/Menu/configuration/reservationsLookup';
import { resourceManagementMenu } from 'frontend-container/components/Menu/configuration/resourceManagement';
import { salesActivitiesMenu } from 'frontend-container/components/Menu/configuration/salesActivities';
import { getReportingMenuItems } from 'frontend-container/components/Menu/configuration/utils/getReportingMenuItems';
import { workflowsMenu } from 'frontend-container/components/Menu/configuration/workflows';
import { getIsMenuV2Enabled } from 'frontend-container/components/Menu/isMenuV2Enabled';
import { MenuElement } from 'frontend-container/components/Menu/types';

import { LoginService, SessionService } from '@ac/library-utils/dist/services';

import { accountsReceivableMenu } from './accountsReceivable';
import { applicationDashboardsMenu } from './application';
import { cashieringMenu } from './cashiering';
import { changelogMenu } from './changelog';
import { travelAgentCommissionsMenu } from './commissions';
import {
  configurationMenu,
  getConfigurationMenuInMainApplication,
} from './configuration';
import { customersMenuFull, customersMenuReadOnlyMode } from './customers';
import { endOfDayMenu } from './endOfDay';
import { frontDeskMenu } from './frontdesk';
import { getReservationsGroupsMenu } from './groups';
import { housekeepingMenu } from './housekeeping';
import {
  integrationsMenu,
  integrationsMenuForSystemUser,
} from './integrations';
import { meetingsAndEventsMenu } from './meetingsAndEvents';
import { organizationStructureMenu } from './organizationStructure';
import { profilesMenu } from './profiles';
import { reportsMenu } from './reports';
import { reservationsMenu } from './reservations';
import { getSystemUsersMenu as getSystemUsersMenuElement } from './systemUsers';
import { taskManagementMenu } from './taskManagement';
import { usersMenu } from './users';

export const menuItemHeightInPx = 42;
const menuHeaderHeightInPx = 32;

/**
 * @privateRemarks
 * Remove with MenuV1
 */
const fillShiftForMenuElement = (menuElement: MenuElement): MenuElement => {
  if (!menuElement.items?.length) {
    menuElement.items = [];
  }

  return {
    ...menuElement,
    shift:
      (menuItemHeightInPx * menuElement.items.length + menuHeaderHeightInPx) /
        2 -
      menuHeaderHeightInPx / 2,
  };
};

const getIntegrationsMenu = (): MenuElement =>
  LoginService.isSuperUser() ? integrationsMenuForSystemUser : integrationsMenu;

export const getProfileCenterMenu = (): MenuElement[] => [
  profileCenterMenu,
  campaignManagementMenu,
];
export const getCentralReservationOfficeMenu = (): MenuElement[] => [
  centralAvailabilityInquiryMenu,
  reservationsLookupMenu,
];
export const getConfigurationMenu = (): MenuElement[] => {
  let menuElements: MenuElement[];
  const isMenuV2Enabled = getIsMenuV2Enabled();

  if (LoginService.isSuperUser()) {
    menuElements = [
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
      getSystemUsersMenuElement(),
      changelogMenu,
      integrationsMenuForSystemUser,
    ];
  } else {
    menuElements = [
      organizationStructureMenu,
      configurationProfileCentersMenu,
      croStructureMenu,
      usersMenu,
      propertyDetailsMenu,
    ];

    if (!isMenuV2Enabled) {
      menuElements = [...menuElements, integrationsMenu];
    }
  }

  return menuElements;
};

export const getFullMenu = (): MenuElement[] => [
  // Change in order may break DocumentTitle service.
  getSystemUsersMenuElement(),
  customersMenuFull,
  getIntegrationsMenu(),
  organizationStructureMenu,
  croStructureMenu,
  ...getCentralReservationOfficeMenu(),
  ...getProfileCenterMenu(),
  usersMenu,
  ...getMainApplicationMenu(),
  applicationDashboardsMenu,
  getConfigurationMenuInMainApplication(),
  changelogMenu,
];

export const getPropertyMenu = (): MenuElement[] => [
  accountsReceivableMenu,
  meetingsAndEventsMenu,
  taskManagementMenu,
  reservationsMenu,
  getGolfMenu(),
  getActivityReservationsMenu(),
  getReservationsGroupsMenu(),
  housekeepingMenu,
  rateManagerMenu,
  workflowsMenu,
  profilesMenu,
  cashieringMenu,
  resourceManagementMenu,
  frontDeskMenu,
  endOfDayMenu,
  {
    ...reportsMenu,
    items: [...reportsMenu.items, ...getReportingMenuItems()],
  },
  travelAgentCommissionsMenu,
  getIntegrationsMenu(),
  changelogMenu,
  inquiryMenu,
  salesActivitiesMenu,
];

export const getAllowedConfigurationMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getConfigurationMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getAllowedCentralReservationOfficeMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getCentralReservationOfficeMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getAllowedProfileCenterMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getProfileCenterMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getAllowedPropertyMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = getPropertyMenu();

  return getAllowedMenuItems(menuElements, configuration);
};

export const getAllowedSystemUserMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [
    SessionService.getReadOnlyMode()
      ? customersMenuReadOnlyMode
      : customersMenuFull,
    getSystemUsersMenuElement(),
    changelogMenu,
    integrationsMenuForSystemUser,
  ];

  return getAllowedMenuItems(menuElements, configuration);
};

export const getMainApplicationMenuV2 = (): MenuElement[] => {
  return [
    getSystemUsersMenuElement(),
    ...getPropertyMenu(),
    ...getCentralReservationOfficeMenu(),
    ...getProfileCenterMenu(),
    ...getConfigurationMenu(),
  ];
};

export const getAllowedMainApplicationMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  if (LoginService.isSuperUser()) {
    return getAllowedSystemUserMenu(configuration);
  }

  return getIsMenuV2Enabled()
    ? getAllowedMenuItems(getMainApplicationMenuV2(), configuration)
    : getAllowedMenuItems(getMainApplicationMenu(), configuration);
};

/**
 * @deprecated
 * For Menu elements
 * - use getAllowedMainApplicationMenu
 *
 * For Separated by context menu elements
 * - getAllowedPropertyItems
 * - getAllowedCentralReservationOfficeMenu
 * - getAllowedProfileCenterItems
 * - getAllowedConfigurationItems
 *
 * For System user menu elements
 * - getAllowedSystemUserMenu
 */
export const getAllowedChangelogMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  let menuElements: MenuElement[];

  if (LoginService.isSuperUser()) {
    menuElements = [
      SessionService.getReadOnlyMode()
        ? customersMenuReadOnlyMode
        : customersMenuFull,
      getSystemUsersMenuElement(),
      changelogMenu,
      integrationsMenuForSystemUser,
    ];
  } else {
    menuElements = [changelogMenu];
  }

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

/**
 * @deprecated
 * For Menu elements
 * - use getAllowedMainApplicationMenu
 *
 * For Separated by context menu elements
 * - getAllowedPropertyItems
 * - getAllowedCentralReservationOfficeMenu
 * - getAllowedProfileCenterItems
 * - getAllowedConfigurationItems
 *
 * For System user menu elements
 * - getAllowedSystemUserMenu
 */
export const getAllowedWorkflowsMenu = (
  configuration: AllAccessConfiguration
): MenuElement[] => {
  const menuElements = [
    workflowsMenu,
    changelogMenu,
    configurationMenu,
    applicationDashboardsMenu,
  ];

  return getAllowedMenuItems(menuElements, configuration).map(
    fillShiftForMenuElement
  );
};

/**
 * @deprecated
 * For Menu elements
 * - use getAllowedMainApplicationMenu
 *
 * For Separated by context menu elements
 * - getAllowedPropertyItems
 * - getAllowedCentralReservationOfficeMenu
 * - getAllowedProfileCenterItems
 * - getAllowedConfigurationItems
 *
 * For System user menu elements
 * - getAllowedSystemUserMenu
 */
export const getMainApplicationMenu = (): MenuElement[] =>
  [
    inquiryMenu,
    profilesMenu,
    reservationsMenu,
    getGolfMenu(),
    getActivityReservationsMenu(),
    resourceManagementMenu,
    rateManagerMenu,
    getReservationsGroupsMenu(),
    frontDeskMenu,
    cashieringMenu,
    housekeepingMenu,
    taskManagementMenu,
    {
      ...reportsMenu,
      items: [...reportsMenu.items, ...getReportingMenuItems()],
    },
    endOfDayMenu,
    meetingsAndEventsMenu,
    salesActivitiesMenu,
    accountsReceivableMenu,
    travelAgentCommissionsMenu,
    getIntegrationsMenu(),
    changelogMenu,
    propertyUsers,
  ].map(fillShiftForMenuElement);
