import { useTranslation } from 'react-i18next';
import { getCurrentPropertyIdentifierColor } from 'frontend-container/components/Menu/components/PropertyContext/service';
import { useRouterContext } from 'frontend-container/components/Router/context/context';
import { useSelectContext } from 'frontend-container/components/Router/utils/useSelectContext';

import { ContextComponent } from '../Context';
import { useBusinessDateTime } from '../Context/components/ContextDropdown/BusinessDate';

interface Props {
  color?: string;
  isContextSwitchHidden?: boolean;
  triggerId?: string;
}

export const PropertyContext = ({
  color,
  isContextSwitchHidden,
  triggerId,
}: Props): JSX.Element => {
  const selectedProperty = useRouterContext((store) => store.selectedProperty);
  const { t } = useTranslation();
  const handleSelect = useSelectContext();

  return !selectedProperty ? (
    <ac-loader-covering />
  ) : (
    <ContextComponent
      selectedContext={selectedProperty}
      useDateTime={useBusinessDateTime}
      onSelect={handleSelect}
      color={color ?? getCurrentPropertyIdentifierColor()}
      dateLabel={t('MENU.CONTEXT.PROPERTY.DATE')}
      timeLabel={t('MENU.CONTEXT.PROPERTY.TIME')}
      isContextSwitchHidden={isContextSwitchHidden}
      triggerId={triggerId}
    />
  );
};
