import { MegaMenuContent } from 'frontend-container/components/Menu/components/MegaMenu/MegaMenuContent';
import { useMegaMenuPresenterContext } from 'frontend-container/components/Menu/components/MegaMenu/presenter/context';

export const MegaMenu = (): JSX.Element | null => {
  const presenter = useMegaMenuPresenterContext();

  return presenter.state.isVisible ? (
    <MegaMenuContent presenter={presenter} />
  ) : null;
};
