import { AllAccessConfiguration } from 'frontend-container/components/Menu/authorization/types';
import { Context } from 'frontend-container/components/Menu/components/Context';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { UserUnitsDetails } from 'frontend-container/components/Prestep/getUserUnitsDetails';

export interface RouterState {
  isInitialized: boolean;
  allAccessConfiguration?: AllAccessConfiguration;
  locationRef: string;
  userMenuElements: MenuElement[];
  selectedCro?: Context;
  selectedProperty?: Context;
  selectedProfileCenter?: Context;
  currentUnit?: Context;
  unitDetails?: UserUnitsDetails;
}

export const getDefaultRouterState = (): RouterState => ({
  isInitialized: false,
  allAccessConfiguration: undefined,
  locationRef: window.location.href,
  userMenuElements: [],
  selectedCro: undefined,
  selectedProperty: undefined,
  selectedProfileCenter: undefined,
  unitDetails: undefined,
});
