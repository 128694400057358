import { AccessSource } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';

import {
  FeatureToggleName,
  GeneralCustomerSettingsKeys,
  ProfilesCustomerSettingsKeys,
} from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const CAMPAIGN_MANAGEMENT_MENU_ID = 'menu-campaign-management';

export const campaignManagementMenu: MenuElement = {
  translation: 'MENU.CAMPAIGN_MANAGEMENT.TITLE',
  icon: IconName.profileDetails,
  id: CAMPAIGN_MANAGEMENT_MENU_ID,
  settings: [
    {
      key: GeneralCustomerSettingsKeys.ProfileCenters,
      source: AccessSource.Tenant,
    },
    {
      key: ProfilesCustomerSettingsKeys.ProfileTagging,
      source: AccessSource.Tenant,
    },
  ],
  featureToggles: [
    {
      key: FeatureToggleName.ProfileTagging,
      source: AccessSource.Tenant,
    },
  ],
  items: [
    {
      link: `${acConfig.newFrontendUrls.profiles}/global-view/campaign-management/tagging`,
      settings: [
        {
          key: GeneralCustomerSettingsKeys.ProfileCenters,
          source: AccessSource.Tenant,
        },
        {
          key: ProfilesCustomerSettingsKeys.ProfileTagging,
          source: AccessSource.Tenant,
        },
      ],
      featureToggles: [
        {
          key: FeatureToggleName.ProfileTagging,
          source: AccessSource.Tenant,
        },
      ],
      id: `${CAMPAIGN_MANAGEMENT_MENU_ID}-campaign-management-dashboard`,
      code: 'ProfilesTagDashboard',
      translation: 'MENU.CAMPAIGN_MANAGEMENT.ITEMS.DASHBOARD',
      aliases: [
        `${acConfig.newFrontendUrls.profiles}/global-view/campaign-management/tagging/dashboard`,
        `${acConfig.newFrontendUrls.profiles}/global-view/campaign-management/tagging/dashboard`,
        `${acConfig.newFrontendUrls.profiles}/global-view/campaign-management/tagging/tag`,
      ],
    },
  ],
};
